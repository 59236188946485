.captcha-input-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.captcha-input-item {
  width: 50px;
  height: 50px;
  margin: auto 5px;
  font-weight: bold;
  font-size: 20px;
  padding: 0;
  border: 1.5px solid #c4c4c4;
  border-radius: 10px;
  text-align: center;
  outline: 0;
}
.captcha-input-item[disabled] {
  background: #fff;
}
.captcha-input-item:focus {
  border: 1px solid #337eff;
}
